@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}


* {
  margin: 0;
  padding: 0;
  font-family: "Cairo", sans-serif !important;
  box-sizing: border-box;
  scroll-behavior: smooth;
  // overflow: hidden;
}
::-webkit-input-placeholder {
  /* Edge */
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #000;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #000;
}

::placeholder {
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #000;
}
#Invoice_print {
  display: none;
}
@media print {
  #Invoice_print {
    display: block;
  }
}

.app{
  display: flex;
  height: 99vh;
  overflow: hidden;
  position: relative;  
}
option {
  background-color: #f5f5f5;
  color: #091e42;
  option:hover {
    background-color: red;
  }
}
.input {
  padding: 0 16px;
  height: 35px;
  margin: 4px 0;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  border-radius: 5px;
  min-width: 240px;
  outline: none;

  &:focus {
    border: 1px solid #eb0028;
  }
}
.button {
  padding: 0 14px;
  border: none;
  font-weight: bold;
  height: 30px;
  border-radius: 8px;
  // margin: 8px 0;
  width: fit-content !important;
  cursor: pointer;
  &:active {
    transform: translateY(1px) translateX(1px);
  }
}

.c70b44d {
  color: white;
  background-color: #70b44d;
  &:hover {
    background-color: #4d9429;
  }
}

.C2578AF {
  color: white;
  background-color: #2578af;
  margin: 0 20px;
  &:hover {
    background-color: #568eb3;
  }
}
.c66BFBF {
  background: #fff;
  color: #ff0063;
  font-weight: 600 !important;
  height: 30px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1px;
}

.ceb0028 {
  color: white;
  background-color: #eb0028;
  &:hover {
    background-color: #ca0022;
  }
}

.cffffff {
  background-color: white;
  color: #828282;
  &:hover {
    color: #eb0028;
    background-color: #f7f7f7;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(196, 196, 196);
  border-radius: 10px;
}
.container {
  justify-content: center;
  // overflow: hidden;
  margin: auto;
}
.navbar-wrapper {
  margin: 20px auto;
  margin-bottom: 10px;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  img {
    cursor: pointer;
  }
  .navbar-left {
    display: flex;
    align-items: center;
  }
}
.table_container {
  overflow: hidden;
  margin: auto;
  width: 90%;
  height: 80vh;
  .thead {
    border-bottom: 1px solid #e5e5e5;
    width: 98%;

    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 5px 0;
    color: #b0b0b0;
    margin-top: 2px;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    .small_width {
      width: 30% !important;
    }
    span {
      width: 100% !important;
      // margin: 0px 2px;
    }
  }
  .odd_row {
    background: #f0f0f0;
  }
  .row_odd {
    height: 90% !important;
    overflow-y: scroll;
    margin-top: 10px;

    div:nth-child(even) {
      background: #f0f0f0;
    }
  }
  .row_even {
    height: 85% !important;

    div:nth-child(even) {
      background: #f0f0f0;
    }
  }
  .body_container {
    width: 98%;
    border: 1px solid #e5e5e5;

    border-radius: 8px;
    height: 15%;
    color: #000;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin: 15px 2px;
    text-align: center;
    cursor: pointer;
    .small_width {
      width: 30% !important;
    }
    span {
      width: 100%;
    }
  }
}
.logo-search-wrapper {
  display: flex;
  align-items: center;
}
.on-time {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #70b44d;
  padding: 0px 8px;
  border-radius: 10px;
  line-height: 25px;
  color: #ffffff;
  width: 70px !important;
  margin: 10px 0;
  height: 20px;
  text-align: center;
}
.on-late {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 0px 8px;
  border-radius: 10px;
  line-height: 25px;
  background: #ff0c35;
  margin: 10px 0;
  height: 20px;

  width: 70px !important;
}
.status-upcoming {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff !important;
  background: #fea120;
  padding: 0px 8px;
  border-radius: 10px;
  line-height: 25px;
  margin: 10px 0;
  height: 20px;

  width: 70px !important;
}
.spinnerDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.pagintation {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  padding: 10px 0;
  font-size: 16px;
  color: #444141;
  // font-weight: 600;
  letter-spacing: 1.2px;
  div {
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    padding: 0px 4px;
    width: 44%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    // height: 50px;
    span {
      display: flex;
    }
  }

  .disable {
    color: #cecac4;
    background-color: rgb(248, 247, 246);
  }
}
.loading_screen {
  direction: rtl;
  text-align: left;
  height: 100vh;
  display: flex;
  align-items: center;
  font-size: 3vw;
  color: #ff0000;
  font-weight: 600;
  letter-spacing: 1.4px;
  justify-content: center;
  text-align: center;
  .tamata_title {
    font-size: 30px;
    span {
      color: black;
    }
  }
}
.alert {
  color: red;
}

.custom_select {
  padding: 0 16px;
  height: 35px;
  margin: 4px 0;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  border-radius: 5px;
  outline: none;
  margin-left: 10px;
  cursor: pointer;
}
.text_overflow {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.popup {
  position: relative !important;
  display: inline-block !important;
  cursor: pointer !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 2px;
  margin-top: 5px;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fixed_text {
  max-width: 7%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 12px;
  font-size: 0.9rem;
}
.small_cell {
  max-width: 20%;
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

td,
th {
  border: 2px solid #bfbfbf;
  text-align: center;
  padding: 2px;
  font-size: 12px;
  height: 10px;
  width: 20px !important;
}

.noteContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  .inputNote {
    outline: none;
    border: 1px solid #939393;
    border-radius: 10px;
    height: 20px;
    padding: 0 10px;
  }
}
.chat_note {
  font-size: 11px;
  font-weight: 600;
  display: block;
  overflow-y: auto;
  height: 60px;
}
.submit_btn {
  height: 25px;
  text-align: center;
}
.late {
  border-radius: 10px;
  line-height: 30px;
  margin: 10px 0;
  background: #eb0028;
  width: 45px !important;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff !important;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 18px;
  color: #6f6f6f;
  font-weight: 600;
  letter-spacing: 1px;
  .title {
    width: fit-content;
    border-bottom: 3px solid rgb(240, 98, 98);
  }
}
.shipping {
  width: 95%;
  margin: 0 auto;
  .ship-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  span {
    font-size: 2rem;
    line-height: 0.5px;
  }
}
.nav {
  background-color: #eb0028;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  color: white;
  :first-child {
    // // margin-top: 2px;
    // display: flex;
    align-items: center;
  }
  div {
    width: 100%;
    // text-align: center;s
    display: flex;
    justify-content: center;
    font-weight: 500;
  }
}
.vendorPick {
  .vendor_pick_contaienr {
    padding: 0 8px;
  }
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    padding: 10px 0;
    font-weight: 500;
    color: #000000;
  }

  .search_container {
    line-height: 19.36px;
    font-size: 18px;
    border-radius: 10px;
    text-align: center;
  }
  .vendor_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .vendor_card {
    width: 45%;
    background-color: #f2f2f2;
    position: relative;
    margin-right: 10px;
    margin-bottom: 20px;
    border-radius: 13px;
    padding: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .id {
      background-color: #fbfbfb;
      position: relative;
      width: 75px;
      height: 32px;
      top: -27%;
      right: 5%;
      padding: 2px 15px;
      font-size: 18px;
      border-radius: 13px 0px;
    }
    .details {
      font-size: 12px;
      letter-spacing: 0.5px;
      color: #000;
     
      span:first-child {
        display: block;
        color: #bfbfbf;
      }
      span:last-child {
        display: block;
      }
    }
    .vendor_actions {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-top: 10px;
      cursor: pointer;

      span {
        background-color: #fbfbfb;
        padding: 8px;
        border-radius: 10px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span:first-child {
        width: 30%;
        margin-right: 10px;
        padding-top: 5px;
      }
      span:last-child {
        width: 70%;
        font-weight: 600;
      }
    }
  }
}
.search-order {
  width: 100%;
  background-color: #fff !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: #000;
  border:1px solid #c4c2c2
}
.order_page {
  .info_header {
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 5px;
    font-size: 16px;
    div {
      div:first-child {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.9px;
      }
      div:last-child {
        font-size: 12px;
        color: #2d2626;
      }
    }
  }
}
.orders_list {
  overflow-y: auto;
  margin: 10px 0;
  height: 60vh;

  .card {
    border-radius: 5px;
    padding: 4px;
    border: 2px solid #ddd;
    margin: 10px;
    .card_info {
      display: flex;
      justify-content: space-between;
      .card_img {
        width: 30%;
      }
      .card_details {
        width: 70%;
      }
    }
    .field_info {
      font-size: 12px;
      color: black;
      width: 100%;
      display: flex;
      word-break: break-all;
      span:first-child {
        color: rgb(122, 122, 122);
      }
    }
    .pick_btn{
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #70B44D;

  
      div{
        display: flex;
        // align-items: center;
        justify-content: center;
        font-size: 16px;
        background-color: #70B44D;
        border: none;
        color: #fff;
        font-weight: 700;
        width: 50%;
        border-radius: 6px;
        letter-spacing:2px;
        margin-top: 2px;
      }
    }
  }

  .card_button {
    border: none;
    outline: none;
    padding: 4px 0;
    color: white;
    background-color: #04aa6d;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    width: 80%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
  }
}
.btn_container {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.item_name{
  color: #000 !important;
  font-weight: 700;
  letter-spacing: 1px;
}
.Leave_btn{
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width:40%;
  background-color: #EB0028;
  color: #fff;
  padding:1px 20px;
  text-align: center;
  border-radius: 10px;
  font-size: 20px;
  letter-spacing:2px;
  font-weight: 600;
  cursor: pointer;
  // margin-top: 6px;
  position: relative;
  bottom: 10%;
  right: 0;
}
.isPicked{
  background-color: #2578AF;
  color: white;
   border-radius: 40%;
   font-size: 14px !important;
  // margin-right: 2px;
  width: 250% !important;

}
.isReplaced{
  background-color: #af2525;
  color: white;
   border-radius: 40%;
   font-size: 14px !important;
  // margin-right: 2px;
  width: 250% !important;

}
.Modal_header {
  display: flex;
  justify-content: space-between;
}

.Confirm {
  padding: 1em 2.1em 1.1em;
  border-radius: 3px;
  margin: 8px 8px 8px 8px;
  display: inline-block;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 0.85em;
  text-align: center;
  text-decoration: none;
  background-color: green;
  padding: 0 12px;
  border: none;
  width: 70px;
  height: 40px;
color: white;
  font-size: 0.90em;
  border-radius: 8px;
  margin: 8px;
  cursor: pointer;
  &:active {
    transform: translateY(1px) translateX(1px);
  }
}
.Decline {
  padding: 1em 2.1em 1.1em;
  border-radius: 3px;
  margin: 8px 8px 8px 8px;
  display: inline-block;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 0.85em;
  text-align: center;
  text-decoration: none;
  background-color: red;
  padding: 0 12px;
  border: none;
  width: 70px;
  height: 40px;
color: white;
font-size: 0.90em;
border-radius: 8px;
  margin: 8px;
  cursor: pointer;
  &:active {
    transform: translateY(1px) translateX(1px);
  }
}
video {
  display: block;
}
.camera_button {
  padding: 0px 8px;
  color: #70b44d;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  background-color: white;
  cursor: pointer;
  &:active {
    transform: translateY(1px) translateX(1px);
  }
  span {
    font-size: 22px;
  }
}
.CallingStatusStyled{
  padding:4px;
  border-radius:10px;
  border:none;
  color:white;
  cursor:pointer;
  text-align: center;
  font-size: 14px;
}

